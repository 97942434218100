.header p{
    text-align: center;
    font-size: 30px;
}

.new-homepage{
    margin: 60px;
}
  
  .header {
    margin-bottom: 60px;
  }
  
  .header h1 {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  
  .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    background: #29176f;
    padding: 10px;
  }
  
  .tabs button {
    background-color: #29176f; /* Tab background color */
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease;
  }
  
  .tabs button.active {
    background-color: #fff;
    color: #4b0082; 
  }
  
  .tabs button:hover {
    background-color: #ffffff;
    color: #29176f;
  }
  
  .content {
    display: flex;
    gap: 20px; }
  
  .card {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  .card-tab{
    position: relative;
    display: flex;
    flex-direction: row;
    min-width: 0;
    word-wrap: break-word;
   
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color:#29176f;
  }
.first-card{
  width: 100%;
}
.second-content{
  flex-direction: column;
}
  .card h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .card p {
    font-size: 1.1rem;
    line-height: 1.6;
  }

