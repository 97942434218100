/* General layout */
.new-homepage-2{

    width: 80%;
    margin-left: 18%;
    margin-top: 8%;
}
.new-homepage-2 .main-container {
    display: flex;
    margin-top: 171px;
    width: 80%;
  }

  .new-homepage-2 .tabs{
    flex-direction: column;
    gap: 20px;
  }
  
  /* Sidebar */
  .new-homepage-2 .sidebar {
    width: 250px;
    background-color:#29176f;
    color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
    justify-content: space-between;
    padding-bottom: 100px;
  }
  
  .new-homepage-2 .tabs button {
    background: none;
    border: none;
    color: #fff;
    padding: 15px;
    text-align: left;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
    border-left: 4px solid transparent;
    transition: 0.3s;
  }
  
  .new-homepage-2 .tabs button:hover,
  .new-homepage-2 .tabs button.active {
    background-color: #fff; /* Active tab background color */
    color: #29176f; /* Active tab text color */
  }
  
  .new-homepage-2 .tabs button.active {
    font-weight: bold;
    color:#29176f;
  }
  
  /* Content area */
  .new-homepage-2 .content-container {
    flex-grow: 1;
    /* background-color: #2a2e35; */
    padding: 30px;
    color: #fff;
  }
  
  .new-homepage-2 .content-card {
    background-color: #fff;/* Card background color */
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .new-homepage-2 .content-card h2 {
    color: #21c6c6;
    font-size: 28px;
    margin-bottom: 10px;
  }
  
  .new-homepage-2 .content-card p {
    font-size: 18px;
    line-height: 1.6;
  }
  
  /* Footer adjustments */
  .new-homepage-2 .new-homepage footer {
    margin-top: 50px;
  }
  
  /* Mobile responsiveness */
  .investment-btn{
    background: white;
    color: black;
    border-radius: 10px;
    padding: 5px 10px;
  }

  .read-btn {
    width: max-content;
    color: #fff;
    background-color: transparent;
    border: 1px solid #fff;
  }

  .read-btn:hover {
    color: #ffffff;
    background-color: #29176f;
    border: 1px solid #29176f;
  }
