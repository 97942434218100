.home-new3 {
  /* padding: 50px 15px; */
  /* background-color: #f5f5f5; */
  /* font-family: Arial, sans-serif; */
  color: #333;
}

.home-new3 h2 {
  font-size: 32px;
  color: #222;
  margin-bottom: 20px;
  font-weight: bold;
  /* text-align: center; */
}

.home-new3 .banner-row {

gap: 40px;
}



.home-new3 .cards-row {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 0 auto 40px;
  flex-wrap: wrap;
}

.home-new3 .card {
  flex: 1;
  min-width: 250px;
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.home-new3 .card h3 {
  font-size: 24px;
  color: #111;
  margin-bottom: 15px;
}

.home-new3 .card p {
  font-size: 16px;
  color: #555;
}

.home-new3 .card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.home-new3 .list-items {
  list-style: none;
  padding: 0;
  max-width: 800px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.home-new3 .list-items li {
  padding: 15px 20px;
  background-color: #fff;
  transition: background-color 0.3s;
}

.home-new3 .list-items li + li {
  border-top: 1px solid #ddd;
}

.home-new3 .list-items li:hover {
  background-color: #f0f0f0;
}

.home-new3 .list-items li strong {
  color: #111;
  font-weight: bold;
}
